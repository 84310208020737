/* You can add global styles to this file, and also import other style files */
@use '@angular/material/core/theming/all-theme';
@use '@angular/material/core/core';
@use '@angular/material/core/theming/palette';
@use '@angular/material/core/theming/theming';


// Include non-theme styles for core.
@include core.core();

$custom-palette: (50: var(--foreground),
  100: var(--foreground),
  200: var(--foreground),
  300: var(--foreground),
  400: var(--foreground),
  500: var(--foreground),
  600: var(--foreground),
  700: var(--foreground),
  800: var(--foreground),
  900: var(--foreground),
  A100: var(--foreground),
  A200: var(--foreground),
  A400: var(--foreground),
  A700: var(--foreground),
  contrast: (50: var(--background),
    100: var(--background),
    200: var(--background),
    300: var(--background),
    400: var(--background),
    500: var(--background),
    600: var(--background),
    700: var(--background),
    800: var(--background),
    900: var(--background),
    A100: var(--background),
    A200: var(--background),
    A400: var(--background),
    A700: var(--background),
  ));


// Define a theme.
$primary: theming.define-palette($custom-palette);
$accent : theming.define-palette($custom-palette, A200, A100, A400);

$theme: theming.define-light-theme((color: (primary: $primary,
      accent: $accent)));

// Include all theme styles for the components.
@include all-theme.all-component-themes($theme);

:root {
  --background        : #f5f5f5;
  --foreground        : #800080;
  --foreground-dark   : #602060;
  --foreground-lighter: #C800C8;
  --foreground-bright : #FFE2FF;

  --foreground-me: rgba(0, 0, 0, 0.87);
  --background-me: #E0E0E0;
}

::-webkit-scrollbar {
  width           : 6px;
  height          : 6px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius   : 12px;
  background-color: #d3d3d3;
}



.banner-wrapper {
  .mat-form-field-outline {
    background-color: white;
    border-radius: 5px;
  }
}